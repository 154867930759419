<template>
    <div style="margin-top: 80px;">
        <BasePageBreadCrumb :pages="pages" :title="id ? 'Editar imobiliária' : 'Nova imobiliária'" :menu="menu"/>
        <div class="container-fluid qsuite-c-fluid-0">
            <div class="col-12">
                <div class="row">
                    <div class="col-12">
                        <div class="card card-bg-trans">
                            <div class="card-body pt-4 px-0">
                                <b-tabs>
                                    <form class="px-2 needs-validation" validate @submit.prevent="save">
    
                                        <b-tab class="tab-pane" id="dadospessoais">
                                            <template #title>
                                                <div class="d-flex">
                                                    <i class="far fa-list-alt font-20 align-middle mr-2"></i><span class="d-none d-lg-block"> Informações <b-badge variant="danger" v-show="error_information">1</b-badge></span>
                                                </div>
                                            </template>

                                            <div class="form-body">
                                                <div class="row mt-4">
                                                    <div class="col-12 col-md-4 col-lg-4">
                                                        <div class="form-group">
                                                            <label>Código do mobi</label>
                                                            <input type="text" v-model="real_state.id" class="form-control" placeholder="#5898" disabled="">
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-4 col-lg-4">
                                                        <div class="form-group">
                                                            <label>Data cadastro</label>
                                                            <input type="date" v-model="real_state.created_at" class="form-control" placeholder="345898">
                                                        </div>
                                                    </div>
                                                   
                                                    <div class="col-12 col-md-4 col-lg-4">
                                                        <div class="form-group">
                                                            <label>Como conheceu a empresa? </label>
                                                            <select v-model="real_state.source" class="custom-select mr-sm-2" id="inlineFormCustomSelect">
                                                                <option >Selecione</option>
                                                                <option value="GOOGLE">Google</option>
                                                                <option value="SOCIAL_MEDIA">Rede Social</option>
                                                                <option value="RADIO">Rádio</option>
                                                                <option value="OUTDOOR">Outdoor</option>
                                                                <option value="MAGAZINE">Revista</option>
                                                                <option value="INDICATION">Indicação</option>
                                                                <option value="OTHER">Outros</option>
                                                            </select>
                                                            <small id="name1" class="badge badge-default badge-info form-text text-white float-left">Definir a origem do lead</small>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 pt-4"></div>
                                                    <div class="col-12 col-md-5 col-lg-5">
                                                        <div class="form-group">
                                                            <label>Nome fantasia *</label>
                                                            <input required v-model="real_state.fantasy_name" type="text" class="form-control" placeholder="">
                                                            <div class="invalid-feedback pl-4 pb-3">Por favor! Informe o nome fantasia</div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-4 col-lg-4">
                                                        <div class="form-group">
                                                            <label>Razão social *</label>
                                                            <input required v-model="real_state.social_name"  type="text" class="form-control" placeholder="">
                                                            <div class="invalid-feedback pl-4 pb-3">Por favor! Informe a razão social.</div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-3 col-lg-3">
                                                        <div class="form-group">
                                                            <label>CNPJ *</label>
                                                            <input required v-model="real_state.cnpj" v-mask="'##.###.###/####-##'" type="tel" class="form-control" value="1-(444)-444-4445">
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-4 col-lg-3">
                                                        <div class="form-group">
                                                            <label>Abertura da empresa </label>
                                                            <input v-model="real_state.real_state_since" type="date" class="form-control" placeholder="">
                                                        </div>
                                                    </div>
                                                 <input type="hidden" v-model="real_state.real_state_address_id" class="form-control" placeholder="" />

                                                    <div class="col-12 col-md-3 col-lg-3">
                                                        <div class="form-group">
                                                            <label>Telefone</label>
                                                            <input v-model="real_state.telephone"  v-mask="'(##) ####-####'" type="text" class="form-control" placeholder="">
                                                        </div>
                                                    </div>

                                                    <div class="col-12 col-md-6 col-lg-6">
                                                        <div class="form-group">
                                                            <label>E-mail comercial*</label>
                                                            <input required v-model="real_state.email" type="email" class="form-control" placeholder="">
                                                        </div>
                                                    </div>
                                                  
                                                    <div class="col-12 col-md-3 col-lg-3">
                                                        <div class="form-group">
                                                            <label>Inscrição estadual</label>
                                                            <input v-model="real_state.insc_state" type="text" class="form-control" placeholder="">
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-3 col-lg-3">
                                                        <div class="form-group">
                                                            <label>Inscrição municipal</label>
                                                            <input v-model="real_state.insc_city" type="text" class="form-control" placeholder="">
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6 col-lg-2">
                                                        <div class="form-group">
                                                            <label>UF </label>
                                                            <select v-model="real_state.state" class="custom-select mr-sm-2" id="inlineFormCustomSelect">
                                                                <option :value="null">Selecione</option>
                                                                 <option v-for="item in states" :key="item.sigla" :value="item.sigla" >{{ item.nome }}</option>
                                                       
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6 col-lg-4">
                                                        <div class="form-group">
                                                            <label>Município </label>
                                                            <select v-model="real_state.city"  class="custom-select mr-sm-2" id="inlineFormCustomSelect">
                                                                <option :value="null">Selecione</option>
                                                                        <option v-for="item in getCities(real_state.state)" :key="item" :value="item">{{ item }}</option>
                                                       
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <!-- <div class="col-12 pt-4 pb-2">
                                                        <h4 class="card-title">Dados do responsável</h4>
                                                    </div>

                                                    <div class="col-12 col-md-5 col-lg-5">
                                                        <div class="form-group">
                                                            <label>Nome</label>
                                                            <input v-model="real_state.representative_first_name" type="text" class="form-control" placeholder="">
                                                        </div>
                                                    </div>

                                                    <div class="col-12 col-md-4 col-lg-4">
                                                        <div class="form-group">
                                                            <label>Sobrenome</label>
                                                            <input v-model="real_state.representative_last_name" type="text" class="form-control" placeholder="">
                                                        </div>
                                                    </div>

                                                    <div class="col-12 col-md-3 col-lg-3">
                                                        <div class="form-group">
                                                            <label>cpf</label>
                                                            <input v-mask="'###.###.###-##'" v-model="real_state.representative_cpf" type="text" class="form-control" placeholder="">
                                                        </div>
                                                    </div>
                                                  
                                                    <div class="col-12 col-md-3 col-lg-3">
                                                        <div class="form-group">
                                                            <label>Data de nascimento</label>
                                                            <input v-model="real_state.representative_birth_date" type="date" class="form-control" placeholder="">
                                                        </div>
                                                    </div>
                                                  
                                                    <div class="col-12 col-md-6 col-lg-6">
                                                        <div class="form-group">
                                                            <label>E-mail pessoal</label>
                                                            <input v-model="real_state.representative_email" type="email" class="form-control" placeholder="">
                                                        </div>
                                                    </div> -->
                                                  
                                                   
                                                   
                                                </div>
                                            </div>
                                        </b-tab>
    
                                    <b-tab class="tab-pane" id="enderecoresidencial">
                                        <template #title>
                                            <div class="d-flex">
                                                <i class="far fa-map font-20 align-middle mr-2"></i><span class="d-none d-lg-block"> Endereço <b-badge variant="danger" v-show="address_error">1</b-badge></span>
                                            </div>
                                        </template>
                                            <div class="form-body">
                                                
                                                <div class="row mt-4">
                                                    <div class="col-12 pt-4 pb-2">
                                                        <h4 class="card-title">Endereço comercial</h4>
                                                    </div>
                                                    <div class="col-12 col-md-4 col-lg-3">
                                                        <div class="form-group">
                                                            <label>CEP </label>
                                                            <input type="tel" class="form-control" v-mask="'#####-###'" placeholder="74.830-500" v-model="address.cep" @change="getAddress">
                                                        </div>
                                                    </div>
                                                    <div class="col-12"></div>
                                                    <div class="col-12 col-md-6 col-lg-4">
                                                        <div class="form-group">
                                                            <label>Rua/Avenida/Alameda/Travessa </label>
                                                            <input type="text" class="form-control" placeholder="" v-model="address.street">
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6 col-lg-2">
                                                        <div class="form-group">
                                                            <label>Número </label>
                                                            <input type="text" class="form-control" placeholder="" v-model="address.number">
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6 col-lg-6">
                                                        <div class="form-group">
                                                            <label>Complemento </label>
                                                            <input type="text" class="form-control" placeholder="" v-model="address.complement">
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6 col-lg-6">
                                                        <div class="form-group">
                                                            <label>Bairro </label>
                                                            <input type="text" class="form-control" placeholder="" v-model="address.neighborhood">
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6 col-lg-2">
                                                        <div class="form-group">
                                                            <label>UF </label>
                                                            <select class="custom-select mr-sm-2" v-model="address.state">
                                                                <option :value="null">Selecione</option>
                                                                <option v-for="item in states" :key="item.sigla" :value="item.sigla" >{{ item.nome }}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6 col-lg-4">
                                                        <div class="form-group">
                                                            <label>Município </label>
                                                            <select class="custom-select mr-sm-2" v-model="address.city">
                                                                <option :value="null">Selecione</option>
                                                                <option v-for="item in getCities(address.state)" :key="item" :value="item">{{ item }}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    </b-tab>
                                   
                                    <b-tab class="tab-pane" id="configuracoes">
                                        <template #title>
                                            <div class="d-flex">
                                                <i class="far fa-sun font-20 align-middle mr-2"></i><span class="d-none d-lg-block"> Configurações <b-badge variant="danger" v-show="settings_error">1</b-badge></span>
                                            </div>
                                        </template>
                                            <div class="form-body">
                                                <div class="row">
                                                    <div class="col-12 col-md-6 col-lg-4 mt-4">
                                                        <div class="form-group">
                                                            <label>Status do cadastro </label>
                                                            <select class="custom-select mr-sm-2" v-model="real_state.is_active">
                                                                <option :value="true">Ativo</option>
                                                                <option :value="false">Inativo</option>
                                                            </select>
                                                        </div>
                                                        <small id="name13" class="badge badge-default badge-danger form-text text-white">Desativar o acesso do cliente na plataforma</small>
                                                    </div>

                                                      <div class="col-12 col-md-6 col-lg-4 mt-4">
                                                        <div class="form-group">
                                                            <label>Exibir landing page do corretor? </label>
                                                            <select class="custom-select mr-sm-2" v-model="real_state.landingpage_can">
                                                                <option value="sim">Sim</option>
                                                                <option value="não">Não</option>
                                                            </select>
                                                        </div>
                                                        <small id="name13" class="badge badge-default badge-danger form-text text-white">Mostrar landing page</small>
                                                    </div>

                                                    <div class="col-12 col-md-6 col-lg-4 mt-4">
                                                        <div class="form-group">
                                                            <label>Permitir outros nomes? </label>
                                                            <select class="custom-select mr-sm-2" v-model="real_state.allow_others">
                                                                <option value="sim">Sim</option>
                                                                <option value="não">Não</option>
                                                            </select>
                                                        </div>
                                                        <small id="name13" class="badge badge-default badge-danger form-text text-white">Permitir nome aleatório</small>
                                                    </div>
                                                    <!-- <div class="col-12 pt-4 pb-2">
                                                        <h4 class="card-title">Senha de acesso</h4>
                                                    </div>
                                                    <div class="col-12 col-md-6 col-lg-4">
                                                        <div class="form-group">
                                                            <label>Resetar senha </label>
                                                            <input type="password" class="form-control" id="passtext" placeholder="********" v-model="user.password" v-validate="passwordValidate" ref="password" name="password" :class="{'is-invalid': errors.has('password')}">
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6 col-lg-4">
                                                        <div class="form-group">
                                                            <label>Confirmar senha </label>
                                                            <input type="password" class="form-control" id="passtext" placeholder="********" v-model="user.confirm_password" v-validate="confirmPasswordValidate" name="confirm_password" :class="{'is-invalid': errors.has('confirm_password')}">
                                                        </div>
                                                        <small id="name1" class="badge badge-default badge-info form-text text-white float-left">Deve ter entre 8 e 20 caracteres.</small>
                                                    </div> -->
                                                    <div class="col-12" v-show="false">
                                                        <div class="form-group">
                                                            <div class="col-12 py-2">
                                                                <i class="fab fa-facebook font-20 align-middle"></i> {{ user.first_name }} {{ user.last_name }}
                                                            </div>
                                                            <button type="submit" class="btn btn-danger">Desvincular conta <i class="pl-1 far fa-trash-alt"></i></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                 
                                    </b-tab>
                                    <div class="form-actions">
                                        <div class="text-right">
                                            <router-link :to="{name: 'RealState'}" class="btn btn-dark">Cancelar</router-link>
                                            <button type="submit" class="btn btn-info ml-2">{{ id ? 'Atualizar' : 'Cadastrar' }}</button>
                                        </div>
                                    </div>
                                    </form>
                                </b-tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </template>
    <script>
    import ImobiliariaService from "@/services/resources/ImobiliariaService";
    import ConsultDataUserService from "@/services/resources/ConsultDataUser";
    import EnterpriseService from "@/services/resources/EnterpriseService";
    import states from '@/assets/js/states.json'
    import axios from 'axios';
    import AreaCode  from "@/components/AreaCode.vue";
    

    const service = ImobiliariaService.build();

    const serviceEnterprise = EnterpriseService.build();
    const consultService = ConsultDataUserService.build();
    
    
    export default {
        components: {
            AreaCode
        },
       data(){
           return{
               real_state:{
                "id": null,
                'fantasy_name': null, 
                'social_name': null,
                'cnpj': null, 
                'telephone': null, 
                'email': null, 
                'insc_state': null,
                'insc_city': null, 
                'allow_others': null,
                'city': null, 
                'landingpage_can': null,
                'is_active': true,
                'state': null, 
                'real_state_since': null, 
                'source': null, 
                'representative_first_name': null,
                'representative_last_name': null, 
                'representative_cpf': null, 
                'representative_birth_date': null,
                'representative_email': null, 
                'real_state_address_id': null,
                'created_at': new Date().toJSON().slice(0,10)
               },
               address:  {
                        cep: null,
                        street: null,
                        number: null,
                        complement: null,
                        neighborhood: null,
                        city: null,
                        state: null,
                        address_type_id: 1
                },
                user: {
                    is_active: true,
                    password: '12345678',
                    confirm_password: '12345678'
                },
               address_error: false,
               settings_error: false,
               enterprise_error: false,
               legal_entity_error: false,
               basic_error: false,
               error_information: false,
               id: null,
               states: [],
               test: [],
               pages:[
                    {
                        name: 'dashboard',
                        to: 'Dashboard'
                    },
                    {
                        name: 'nova imobiliaria',
                        to: 'RealStateNew'
                    }
                ],
                menu:[
                    {
                        name: 'Nova imobiliária',
                        to: 'RealStateNew'
                    },
                    {
                        name: 'Clientes',
                        to: 'Client'
                    }
                ],
                enterprise_id: null,
                floors: [],
                enterprises_list: [],
                floor: null,
                enterprise_index: null
           }
       },
       computed:{
           passwordValidate(){
               return this.id ? '' : 'required|min:8';
           },
           confirmPasswordValidate(){
               return this.id ? '' : 'required|confirmed:password';
           }
       },
       methods:{
            setPhoneAreaCode(value) {
           
                this.$set(this.client, 'telephone_area_code', value);
            },
            setCellPhoneAreaCode(value) {
                this.$set(this.client, 'cellphone_area_code', value);
            },
            setCompanyAreaCode(value) {
                this.$set(this.client, 'company_phone_area_code', value);
            },
           getAddress(){
              
              if(this.address.cep.length === 9) {
                let url = `https://viacep.com.br/ws/${this.address.cep.replace('-', '').replace('.', '')}/json`;
    
                    axios.get(url).then(resp =>{
                        this.address.street = resp.data.logradouro;
                        this.address.neighborhood = resp.data.bairro;
                        this.address.state = resp.data.uf;
                        this.address.city = resp.data.localidade;
                    });
              }
    
       
           },
     
           verifyDataUser(type){
    
                let data     = {};
                data['role_id'] = 2;//CLIENT
                
                if(type === 'cpf') {
      
                    data['cpf'] = this.user.cpf;
          
                    consultService
                        .search(data)
                        .catch(err => {
                            this.$bvToast.toast('Cpf já utilizado para este tipo de usuário', {
                                title: 'Criação do cliente',
                                autoHideDelay: 5000,
                                type: 'danger'
                            })
                        })
    
                } else {
    
                        data['email'] = this.user.email;
                
                         consultService
                        .search(data)
                        .catch(err => {
                            this.$bvToast.toast('E-mail já utilizado para este tipo de usuário', {
                                title: 'Criação do cliente',
                                autoHideDelay: 5000,
                                type: 'danger'
                            })
                        })
    
                }
    
           },
   
           getype(value){       
    
            this.$delete(this.client, 'type_registration');
            this.$set(this.client, 'type_registration', value);

            if(value === 'natural_person') {
                this.$set(this.client, 'cnpj', '');
            } else {
                this.$set(this.client, 'cpf', '');
            }
        
        },
           getCities(uf){
               
               if(!uf)
                    return []
    
               let state = this.states.filter(elem => {
                   return elem.sigla === uf;
               });
               if(!state.length)
                    return state;
                    
               return state[0].cidades;
    
           },
          
         
           getEnterprises(enterprises){
               let data = [];
    
                for(let i = 0; i < this.enterprises.length; i++){
                    for(let j = 0; j < enterprises.length; j++){
                        if(this.enterprises[i].id == enterprises[j].enterprise_id){
                            data.push(this.enterprises[i]);
                            data.floors = enterprises[j].floors.map(elem => {
                                return elem.apto
                            });   
                        }
                    }
                }
    
                return data;
    
           },
           save(){
            this.basic_error = false;
            this.settings_error = false;
            this.enterprise_error = false;
            
            this.$validator.validateAll().then((result) => {
              
               // if (this.validateInformationTab() & this.legalEntityError() & this.validateBasicTab() & this.validateSettingTab() & this.validateEnterpriseTab()) {
                    
                    //let client = Object.assign({}, this.client)
                   // user.type = this.user.type.join(',');

                    if(this.id){   
                     
                       service
                        .update({real_state: this.real_state, address: this.address, id: this.id})
                        .then(resp => {
                            this.$bvToast.toast('Imobiliária foi atualizada com sucesso!', {
                                title: 'Imobiliária atualizado',
                                autoHideDelay: 5000,
                                type: 'success'
                            })
                            this.fetchClient();
                        })
                        .catch(err => {
                            this.$bvToast.toast('Este e-mail já foi cadastrado no sistema.', {
                                title: 'Criação de Imobiliária',
                                autoHideDelay: 5000,
                                type: 'danger'
                            })
                        })
                    }else{
                       service
                        .create({real_state: this.real_state, user: this.user, address: this.address})
                        .then(resp => {
                            this.$bvToast.toast('Imobiliária foi criado com sucesso!', {
                                title: 'Imobiliária criada',
                                autoHideDelay: 5000,
                                type: 'success'
                            })
                            this.fetchClient();
                        })
                        .catch(err => {
                            this.$bvToast.toast('Erro ao cadastrar imobiliária', {
                                title: 'Criação de Imobiliária',
                                autoHideDelay: 5000,
                                type: 'danger'
                            })
                        })
                    }
                    
    
              
                // } else {
                //     this.$bvToast.toast('Preencha os dados', {
                //                 title: 'Preencha todos os dados',
                //                 autoHideDelay: 5000,
                //                 type: 'error'
                //             })
                // }
            });
           },
           fetchClient(){
    
                if(!this.id){
                    return;
                }
    
                let data = {
                    id: this.id
                }
    
                service
                .read(data)
                .then(resp => {
                    this.real_state = resp
                })
                .catch(err => {
                 
                console.log(err)
                })
            }
      },
    
       mounted(){

        
        $('[type="submit"]').on('click', function () {
            $(this)
            .closest('form')
            .find('[required]')
            .addClass('required');              
        });
      

           this.states = states;
           this.id = this.$route.params.id;
           console.log(this.id);
           this.fetchClient();
       }
    }
    </script>


<style>
input.required:invalid    {
 border-color: red !important;
}
</style>